<template>
  <div>
    <div class="delivery-title left" v-if="window.width > 1003">
      <h1>{{ $t("deliveryPointsName") }}</h1>
      <p>{{ $t("deliveryPointsDescription") }}</p>
    </div>
    <div class="right">
      <a
        v-if="
          customer &&
            customer.role !== 'FAC' &&
            !customer.state_owned &&
            customer.user_config &&
            customer.user_config.roles &&
            customer.user_config.roles.includes('EDITOR')
        "
        class="notification-action"
        @click="addNewWorkingPoint()"
        >{{ $t("addDeliveryPointText") }}</a
      >
    </div>
    <br clear="all" />
    <div class="tabs-container">
      <div class="tabs">
        <a
          v-for="(entry, index) in customer.products"
          :key="index"
          @click="selectProduct(index)"
          :class="entry.name === selectedProduct.name && 'active'"
        >
          <img
            :src="
              require('@/assets/images/logo-up-' +
                (entry.card_profile
                  ? entry.card_profile
                  : entry.card
                  ? entry.card
                  : entry.paper) +
                '.png')
            "
            class="logo-product"
          />
        </a>
      </div>
    </div>
    <div align="center" class="select-left">
      <select class="mobile" @change="selectedProductMobile">
        <option
          v-for="(entry, index) in customer.products"
          :key="index"
          :value="index"
          :selected="isSelectedProduct(entry)"
        >
          {{ entry.name }}
        </option>
      </select>
    </div>
    <div class="content-delivery">
      <div class="second-tabs">
        <a
          v-if="this.selectedProduct.paper !== 0"
          href="#"
          class="tickets-type paper cursor-pointer"
          :class="
            this.environment === 'paper' && 'active remove-cursor-pointer'
          "
          @click="getAddressPoints('paper', selectedProduct.brand)"
          >{{ $t("productTypePaper") }}</a
        >
        <a
          v-if="this.selectedProduct.card !== 0 && this.selectedProduct.paper !== 0"
          href="#"
          class="tickets-type cardst cursor-pointer"
          :class="this.environment === 'card' && 'active remove-cursor-pointer'"
          @click="getAddressPoints('card', selectedProduct.brand)"
          >{{ $t("productTypeCard") }}</a
        >
        <br clear="all" />
      </div>

      <div class="second-tabs">
        <div v-if="this.gettingAddressPoints" class="loading">
          <img src="@/assets/images/loading.gif" />
        </div>
        <div v-else>
          <div v-for="(point, index) in addressPoints" :key="index">
            <h3>{{ point.codcom }} - {{ point.name }}</h3>
            <div class="grey-info">
              <b-container fluid class="border-box">
                <form
                  @submit.prevent="
                    {
                      return false;
                    }
                  "
                >
                  <b-row class="border-box">
                    <b-col
                      xl="10"
                      md="10"
                      class="border-box"
                      :class="
                        window.width > 767 &&
                          environment === 'paper' &&
                          'col-xl-12 col-md-12'
                      "
                    >
                      <b-row class="border-box">
                        <b-col class="border-box" xl="12" md="12">
                          <p class="title-address-table">
                            <strong>{{
                              $t("deliveryPointAddressTitle")
                            }}</strong>
                          </p>
                        </b-col>
                        <!-- <b-col class="border-box" xl="3" lg="6" md="6">
                        <p>
                          <small>{{ $t("workingPointAddress1") }}</small>
                          <br />
                          <input
                            v-if="point !== selectedPoint"
                            type="text"
                            v-model="point.address.street_detail"
                            class="formedituser"
                            maxlength="32"
                            disabled
                          />
                          <input
                            v-else
                            type="text"
                            v-model="point.succursal.address.street_detail"
                            class="formedituser"
                            maxlength="32"
                          />
                        </p>
                      </b-col> -->
                        <b-col class="border-box" xl="4" lg="4" md="4">
                          <p>
                            <small>{{ $t("workingPointAddress") }}</small>
                            <br />
                            <input
                              v-if="point !== selectedPoint"
                              type="text"
                              v-model="point.address.street_name"
                              class="formedituser"
                              maxlength="64"
                              disabled
                            />
                            <input
                              v-else
                              type="text"
                              v-model="point.succursal.address.street_name"
                              class="formedituser"
                              maxlength="64"
                              @keyup="updateInputCharacters()"
                            />
                          </p>
                        </b-col>
                        <b-col class="border-box" xl="4" lg="4" md="4">
                          <p>
                            <small>{{
                              $t("deliveryPointAddressDistrict")
                            }}</small>
                            <br />
                            <input
                              v-if="point !== selectedPoint"
                              type="text"
                              v-model="point.address.county"
                              class="formedituser"
                              disabled
                            />
                            <multiselect
                              v-else
                              v-model="point.address.county_obj"
                              :options="county"
                              class="margin-top-1rem"
                              :placeholder="$t('selectLabel')"
                              select-label=""
                              selected-label=""
                              deselect-label=""
                              track-by="id"
                              label="value"
                              :loading="isLoading"
                              @input="
                                getNomenclatures(
                                  'locality',
                                  point.address.county_obj.id
                                )
                              "
                            />
                          </p>
                        </b-col>
                        <b-col class="border-box" xl="4" lg="4" md="4">
                          <p>
                            <small>{{ $t("deliveryPointAddressCity") }}</small>
                            <br />
                            <input
                              v-if="point !== selectedPoint"
                              type="text"
                              v-model="point.address.locality"
                              class="formedituser"
                              disabled
                            />
                            <multiselect
                              v-else
                              v-model="point.address.locality_obj"
                              :options="locality"
                              class="margin-top-1rem"
                              :placeholder="$t('selectLabel')"
                              select-label=""
                              selected-label=""
                              deselect-label=""
                              track-by="id"
                              label="value"
                              :loading="isLoading"
                              :disabled="point !== selectedPoint"
                              @open="locality = map.get('locality')"
                              @input="updateMultiSelect()"
                            />
                          </p>
                        </b-col>
                        <!-- <b-col class="border-box" xl="4" md="4">
                        <p>
                          <small>
                            {{ $t("deliveryPointAddressPostalCode") }}
                          </small>
                          <br />
                          <input
                            disabled
                            type="text"
                            v-model="point.address.postal_code"
                            class="formedituser"
                          />
                        </p>
                      </b-col> -->
                      </b-row>
                      <b-row class="border-box">
                        <b-col class="border-box" xl="12" md="12">
                          <p class="title-address-table">
                            <strong>{{
                              $t("deliveryPointContactTitle")
                            }}</strong>
                          </p>
                        </b-col>
                        <b-col class="border-box" xl="3" lg="6" md="6">
                          <p>
                            <small>{{
                              $t("deliveryPointContactSurname")
                            }}</small>
                            <br />
                            <input
                              v-if="point !== selectedPoint"
                              type="text"
                              v-model="point.person.first_name"
                              class="formedituser"
                              disabled
                            />
                            <input
                              v-else
                              type="text"
                              v-model="point.succursal.person.first_name"
                              class="formedituser"
                              @keyup="updateInputCharacters()"
                            />
                          </p>
                        </b-col>
                        <b-col class="border-box" xl="2" lg="6" md="6">
                          <p>
                            <small>{{ $t("deliveryPointContactName") }}</small>
                            <br />
                            <input
                              v-if="point !== selectedPoint"
                              type="text"
                              v-model="point.person.last_name"
                              class="formedituser"
                              disabled
                            />
                            <input
                              v-else
                              type="text"
                              v-model="point.succursal.person.last_name"
                              class="formedituser"
                              @keyup="updateInputCharacters()"
                            />
                          </p>
                        </b-col>
                        <b-col
                          v-if="point === selectedPoint"
                          class="border-box"
                          xl="2"
                          lg="6"
                          md="6"
                        >
                          <p>
                            <small>{{
                              $t("workingPointPersonPosition")
                            }}</small>
                            <br />
                            <!-- <input
                            v-if="point !== selectedPoint"
                            type="text"
                            v-model="point.person.function"
                            class="formedituser"
                            disabled
                          /> -->
                            <multiselect
                              v-model="point.person.function_obj"
                              :options="work"
                              class="margin-top-1rem"
                              :placeholder="$t('selectLabel')"
                              select-label=""
                              selected-label=""
                              deselect-label=""
                              track-by="id"
                              label="value"
                              :loading="isLoading"
                              :disabled="point !== selectedPoint"
                              @input="updateMultiSelect()"
                            />
                          </p>
                        </b-col>
                        <b-col class="border-box" xl="3" lg="6" md="6">
                          <p>
                            <small>{{ $t("deliveryPointContactEmail") }}</small>
                            <br />
                            <input
                              v-if="point !== selectedPoint"
                              type="email"
                              v-model="point.person.email"
                              class="formedituser"
                              maxlength="50"
                              disabled
                            />
                            <input
                              v-else
                              type="email"
                              v-model="point.succursal.person.email"
                              class="formedituser"
                              maxlength="50"
                              @keyup="updateInputCharacters()"
                            />
                          </p>
                        </b-col>
                        <b-col class="border-box" xl="2" lg="6" md="6">
                          <p>
                            <small>{{
                              $t("deliveryPointContactMobile")
                            }}</small>
                            <br />
                            <input
                              v-if="point !== selectedPoint"
                              type="text"
                              v-model="point.person.mobile_phone"
                              class="formedituser"
                              maxlength="14"
                              minlength="14"
                              disabled
                            />
                            <input
                              v-else
                              type="text"
                              v-model="point.succursal.person.mobile_phone"
                              class="formedituser"
                              maxlength="14"
                              minlength="14"
                              @keyup="updateInputCharacters()"
                            />
                          </p>
                        </b-col>
                      </b-row>
                      <br clear="all" />
                      <b-row
                        class="border-box"
                        v-if="
                          point !== selectedPoint &&
                            customer &&
                            customer.role !== 'FAC' &&
                            !customer.state_owned &&
                            customer.user_config &&
                            customer.user_config.roles &&
                            customer.user_config.roles.includes('EDITOR')
                        "
                      >
                        <b-col class="border-box" md="12">
                          <p>
                            <a
                              class="
                                notification-action
                                custom-notification-action
                              "
                              @click="editAddressPoint(index)"
                              >{{ $t("editButtonLabel") }}</a
                            >
                          </p>
                        </b-col>
                      </b-row>
                      <b-row
                        class="border-box"
                        v-if="
                          point === selectedPoint &&
                            customer &&
                            customer.role !== 'FAC' &&
                            !customer.state_owned &&
                            customer.user_config &&
                            customer.user_config.roles &&
                            customer.user_config.roles.includes('EDITOR')
                        "
                      >
                        <b-col class="border-box" cols="6" md="6">
                          <p>
                            <a
                              class="notification-action"
                              @click="
                                showModalPopup('modal_popup_confirm', true)
                              "
                              >{{ $t("saveChangesLabel") }}</a
                            >
                          </p>
                        </b-col>
                        <b-col class="border-box" cols="6" md="6">
                          <p class="line-height-43 text-right">
                            <a
                              class="resetButton"
                              @click="resetAddressPoint(point)"
                            >
                              <i>{{ $t("cancelChangesLabel") }}</i>
                            </a>
                          </p>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col
                      xl="2"
                      md="2"
                      class="border-box"
                      v-if="environment !== 'paper'"
                    >
                      <p class="title-address-table">
                        <strong>{{
                          $t("deliveryPointNoBeneficiaries")
                        }}</strong>
                      </p>
                      <p class="icon-number-beneficiaries">
                        <img src="@/assets/images/user-gri.svg" width="24" />
                      </p>
                      <h3 class="number-beneciaries">
                        <strong>{{ point.beneficiary }}</strong>
                      </h3>
                    </b-col>
                  </b-row>
                </form>
              </b-container>
            </div>
          </div>
        </div>
        <p
          v-if="this.addressPoints === null || this.addressPoints.length === 0"
        >
          {{ $t("deliveryNoHasAddress") }}
        </p>
      </div>
    </div>
    <div id="modal_popup_confirm" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_confirm', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <!-- <p>{{ $t("deliveryPointConfirmData") }}</p>
        <br> -->
        <b-form-checkbox
          id="allProducts"
          v-model="allProducts"
          name="allProducts"
        >
          <span>{{ $t("deliveryPointAllProducts") }}</span>
        </b-form-checkbox>
        <br />
        <small v-if="this.environment === 'card'">{{
          $t("warningCardAddressPoint")
        }}</small>
        <br />
        <br />
        <a class="notification-action" @click="submitAddressPoint()">{{
          $t("saveChangesLabel")
        }}</a>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_info', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.sendAddressError">
          <p>{{ $t("errorSentAddressPoint_" + this.sendAddressError.code) }}</p>
        </div>
        <div v-else>
          <p>{{ $t("sentAddressPointSuccess") }}</p>
        </div>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import httpServiceUser from "@/http/http-user.js";
import httpServiceCommon from "@/http/http-common.js";
import httpServiceCustomer from "@/http/http-customer.js";
import Cookies from "js-cookie";
import { BContainer, BRow, BCol, BFormCheckbox } from "bootstrap-vue";
import utils from "@/plugins/utils.js";

export default {
  props: {
    customer: Object,
    msg: String
  },
  components: {
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol,
    "b-form-checkbox": BFormCheckbox
  },
  data() {
    return {
      selectedProduct: null,
      addressPoints: null,
      environment: null,
      window: {
        width: 0,
        height: 0
      },
      isLoading: false,
      gettingAddressPoints: false,
      editMode: false,
      formData: null,
      selectedPoint: null,
      allProducts: false,
      map: new Map(),
      county: [],
      locality: [],
      work: [],
      oldValue: null,
      sendAddressError: null
    };
  },
  methods: {
    selectProduct(index) {
      this.selectedProduct = this.customer.products[index];
       let environment = "card";
      if (this.selectedProduct.paper === 4 || this.selectedProduct.paper === 6) {
         environment = "paper";
      }
      this.getAddressPoints(environment, this.selectedProduct.brand);
    },
    isSelectedProduct(product) {
      return product + "" === this.selectedProduct;
    },
    selectedProductMobile(event) {
      this.selectedProduct = this.customer.products[event.target.value];
      this.getAddressPoints("paper", this.selectedProduct.brand);
    },
    getAddressPoints(env, brand) {
      this.environment = env;
      this.gettingAddressPoints = true;
      this.editMode = false;
      var params =
        "?brand=" +
        brand +
        "&type=customer" +
        "&environment=" +
        this.environment +
        "&per_page=10000&page=1";

      httpServiceUser.userService
        .getAddressPoints(
          this.customer.company_id,
          params,
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200) {
            this.addressPoints = response.data;
            this.showModalPopup("modal_popup_loading", false);
            this.gettingAddressPoints = false;
          } else if (response.status == 400 || response.status == 403) {
            this.showModalPopup("modal_popup_loading", false);
            this.gettingAddressPoints = false;
            return null;
          } else {
            this.showModalPopup("modal_popup_loading", false);
            this.gettingAddressPoints = false;
            return null;
          }
        })
        .catch(error => {
          this.showModalPopup("modal_popup_loading", false);
          this.gettingAddressPoints = false;
          return null;
        });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    addNewWorkingPoint() {
      this.addressPoints.unshift({
        address: {
          county: "",
          locality: "",
          postal_code: "",
          street_name: ""
        },
        codcom: 0,
        name: "",
        person: {
          email: "",
          first_name: "",
          last_name: "",
          mobile_phone: ""
        },
        succursal: {
          address: {
            street_name: "",
            street_nr: null,
            street_detail: "",
            hub: null,
            locality_id: null,
            locality: null,
            county_id: null,
            county: null,
            region: null,
            country: null,
            postal_code: null,
            gps_latitude: null,
            gps_longitude: null
          },
          person: {
            first_name: "",
            last_name: "",
            email: "",
            mobile_phone: "",
            land_phone: null,
            function: null,
            product:
              this.environment === "paper"
                ? this.selectedProduct.paper
                : this.selectedProduct.card,
            allProducts: false,
            codhd: this.customer.company_id
          },
          succursalNumber: null,
          allProducts: false,
          product:
            this.environment === "paper"
              ? this.selectedProduct.paper
              : this.selectedProduct.card,
          codhd: this.customer.company_id
        }
      });
      // this.selectedPoint = this.addressPoints[0]
    },
    editAddressPoint(index) {
      this.editMode = true;
      this.addressPoints[index].succursal = {
        address: {
          street_name: this.addressPoints[index].address.street_name,
          street_nr: null,
          street_detail: this.addressPoints[index].address.street_detail,
          hub: null,
          locality_id: this.addressPoints[index].address.locality_obj
            ? this.addressPoints[index].address.locality_obj.id
            : null,
          locality: null,
          county_id: this.addressPoints[index].address.county_obj
            ? this.addressPoints[index].address.county_obj.id
            : null,
          county: null,
          region: null,
          country: null,
          postal_code: null,
          gps_latitude: null,
          gps_longitude: null
        },
        person: {
          first_name: this.addressPoints[index].person.first_name,
          last_name: this.addressPoints[index].person.last_name,
          email: this.addressPoints[index].person.email,
          mobile_phone: this.addressPoints[index].person.mobile_phone,
          land_phone: "",
          functionId: this.addressPoints[index].address.function_obj
            ? this.addressPoints[index].address.function_obj.id
            : null,
          product:
            this.environment === "paper"
              ? this.selectedProduct.paper
              : this.selectedProduct.card,
          allProducts: this.allProducts,
          codhd: this.customer.company_id
        },
        succursalNumber:
          this.addressPoints[index].codcom === 0
            ? null
            : this.addressPoints[index].codcom,
        allProducts: this.allProducts,
        product:
          this.environment === "paper"
            ? this.selectedProduct.paper
            : this.selectedProduct.card,
        codhd: this.customer.company_id
      };
      this.selectedPoint = this.addressPoints[index];
      this.county = this.map.get("county");
      this.work = this.map.get("work");
      this.selectedPoint.address.county_obj = this.county.find(
        x => x.value === this.selectedPoint.address.county
      );
      this.selectedPoint.person.function_obj = this.work.find(
        x => x.value === this.selectedPoint.person.function
      );
      this.getNomenclatures(
        "locality",
        this.selectedPoint.address.county_obj.id
      );
    },
    submitAddressPoint() {
      this.selectedPoint.succursal.address.locality_id = this.selectedPoint
        .address.locality_obj
        ? this.selectedPoint.address.locality_obj.id
        : null;
      this.selectedPoint.succursal.address.county_id = this.selectedPoint
        .address.locality_obj
        ? this.selectedPoint.address.locality_obj.id
        : null; //this.selectedPoint.address.county_obj ? this.selectedPoint.address.county_obj.id : null;
      this.selectedPoint.succursal.person.functionId = this.selectedPoint.person
        .function_obj
        ? this.selectedPoint.person.function_obj.id
        : null;
      this.selectedPoint.succursal.person.allProducts = this.allProducts;
      this.selectedPoint.succursal.allProducts = this.allProducts;

      this.sendAddressError = null;
      this.showModalPopup("modal_popup_loading", true);

      if (
        this.selectedPoint.succursal.address.street_name.length === 0 ||
        this.selectedPoint.succursal.address.street_name.length > 64
      ) {
        this.sendAddressError = { code: "NO_LENGTH_ADDRESS" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (!this.selectedPoint.address.county_obj) {
        this.sendAddressError = { code: "NO_LENGTH_COUNTY" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (!this.selectedPoint.address.locality_obj) {
        this.sendAddressError = { code: "NO_LENGTH_LOCALITY" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (this.selectedPoint.succursal.person.first_name.length === 0) {
        this.sendAddressError = { code: "NO_LENGTH_FIRSTNAME" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (this.selectedPoint.succursal.person.last_name.length === 0) {
        this.sendAddressError = { code: "NO_LENGTH_LASTNAME" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (!this.selectedPoint.person.function_obj) {
        this.sendAddressError = { code: "NO_LENGTH_FUNCTION" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (
        this.selectedPoint.succursal.person.email.length > 50 
      ) {
        this.sendAddressError = { code: "NO_LENGTH_EMAIL" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (
        this.selectedPoint.succursal.person.mobile_phone.length === 0 ||
        this.selectedPoint.succursal.person.mobile_phone.length < 9 ||
        this.selectedPoint.succursal.person.mobile_phone.length > 14
      ) {
        this.sendAddressError = { code: "NO_LENGTH_MOBILE" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else if (
        this.selectedPoint.succursal.person.first_name.length +
          this.selectedPoint.succursal.person.last_name.length +
          this.selectedPoint.succursal.person.mobile_phone.length >
        30
      ) {
        this.sendAddressError = { code: "TOO_LENGTH_COMBO" };
        this.closeAndOpenPopup("submitAddressPoint");
        return;
      } else {
        this.selectedPoint.succursal.address.street_name = this.selectedPoint.succursal.address.street_name
          .toUpperCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "");
        this.selectedPoint.succursal.person.first_name = this.selectedPoint.succursal.person.first_name
          .toUpperCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "");
        this.selectedPoint.succursal.person.last_name = this.selectedPoint.succursal.person.last_name
          .toUpperCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "");
        this.selectedPoint.succursal.address.street_detail = this.selectedPoint.succursal.address.street_name.substring(
          0,
          32
        );
        this.selectedPoint.succursal.address.street_name = this.selectedPoint.succursal.address.street_name.substring(
          32
        );

        httpServiceCustomer.customerService
          .changeAddressPoint(
            this.customer.company_id,
            this.selectedPoint.succursal,
            Cookies.get("token")
          )
          .then(response => {
            if (response.status == 200 || response.status == 204) {
              this.sendAddressError = null;
              this.selectedPoint.succursal.address.street_name =
                this.selectedPoint.succursal.address.street_detail +
                this.selectedPoint.succursal.address.street_name;
              this.getAddressPoints(
                this.environment,
                this.selectedProduct.brand
              );
              this.closeAndOpenPopup("submitAddressPoint");
            } else {
              this.sendAddressError = { code: "NOT_SENT" };
              this.selectedPoint.succursal.address.street_name =
                this.selectedPoint.succursal.address.street_detail +
                this.selectedPoint.succursal.address.street_name;
              this.closeAndOpenPopup("submitAddressPoint");
            }
          })
          .catch(error => {
            if (error.response) {
              this.sendAddressError = { code: "NOT_SENT" };
              this.selectedPoint.succursal.address.street_name =
                this.selectedPoint.succursal.address.street_detail +
                this.selectedPoint.succursal.address.street_name;
              this.closeAndOpenPopup("submitAddressPoint");
              // this.companyDataError = { description: error.response.status };
            } else {
              this.sendAddressError = { code: "NOT_SENT" };
              this.selectedPoint.succursal.address.street_name =
                this.selectedPoint.succursal.address.street_detail +
                this.selectedPoint.succursal.address.street_name;
              this.closeAndOpenPopup("submitAddressPoint");
            }
          });
      }
    },
    resetAddressPoint(addressPoint) {
      this.formData = addressPoint;
      this.selectedPoint = null;
      this.editMode = false;
    },
    customLabelPersons({ first_name, last_name }) {
      return `${first_name}  ${last_name}`;
    },
    getNomenclatures(
      item,
      countyId = null,
      shopProductId = null,
      shopProfileId = null
    ) {
      this.isLoading = true;

      let url = "type=" + item + "&per_page=200&page=1";

      if (item === "locality") {
        url += "&county=" + countyId;
      } else if (item === "shop_type") {
        url +=
          "&shop_product=" + shopProductId + "&shop_profile=" + shopProfileId;
      }

      // if (this.map.get(item)){
      //   return this.map.get(item);
      // } else {
      httpServiceCommon.companyService
        .getNomenclature(url, Cookies.get("token"))
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            this.map.set(item, response.data);
            this.isLoading = false;
            if (item === "locality") {
              this.locality = this.map.get("locality");
              this.selectedPoint.address.locality_obj = this.locality.find(
                x => x.value === this.selectedPoint.address.locality
              );
            }
          } else {
            // this.companyDataError = { description: response.status };
          }
        })
        .catch(error => {
          if (error.response) {
            this.isLoading = true;
            // this.companyDataError = { description: error.response.status };
          } else {
            // this.companyDataError = { description: "undefined" };
          }
        });
      // }
    },
    selectLocality(countyId) {
      this.getNomenclatures("locality", countyId);
    },
    closeAndOpenPopup(type) {
      if (type === "submitAddressPoint") {
        this.showModalPopup("modal_popup_loading", false);
        this.showModalPopup("modal_popup_confirm", false);
        this.showModalPopup("modal_popup_info", true);
      }
    },
    onlyAlphabetCharacters(variable) {
      if (/^[a-zA-Z]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[0-9&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g, "");
      }
    },
    onlyNumericCharacters(variable) {
      if (/^[0-9]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(
          /[a-zA-Z&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g,
          ""
        );
      }
    },
    onlyAlphaNumericCharacters(variable) {
      if (/^[a-zA-Z0-9@.-]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[&\/\\|`#\[\]\-,+=()$~%'":*!?<>{}]/g, "");
      }
    },
    updateInputCharacters() {
      this.selectedPoint.succursal.address.street_name = this.onlyAlphaNumericCharacters(
        this.selectedPoint.succursal.address.street_name
      );
      this.selectedPoint.succursal.person.first_name = this.onlyAlphabetCharacters(
        this.selectedPoint.succursal.person.first_name
      );
      this.selectedPoint.succursal.person.last_name = this.onlyAlphabetCharacters(
        this.selectedPoint.succursal.person.last_name
      );
      this.selectedPoint.succursal.person.mobile_phone = this.onlyNumericCharacters(
        this.selectedPoint.succursal.person.mobile_phone
      );
      this.selectedPoint.succursal.person.email = this.onlyAlphaNumericCharacters(
        this.selectedPoint.succursal.person.email
      );
      this.$forceUpdate();
    },
    updateMultiSelect() {
      this.$forceUpdate();
    }
  },
  created() {
    this.selectProduct(0);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.token = Cookies.get("token");
    this.getNomenclatures("county");
    this.getNomenclatures("work");
  },
  watch: {
    oldValue: function(newValue, oldValue) {}
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/assets/styles/multiselect.css"></style>
<style scoped src="@/assets/styles/beneficiary.css"></style>

<style scoped>
.number-beneciaries {
  display: inline-block;
  width: auto;
  padding: 5px 10px;
  font-size: 16px;
}
.icon-number-beneficiaries {
  display: inline-block;
  width: auto;
}
.margin-top-1rem {
  margin-top: 1rem;
}
.line-height-43 {
  line-height: 43px;
}
</style>
<style>
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
</style>
