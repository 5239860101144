import { render, staticRenderFns } from "./DeliveryPoints.vue?vue&type=template&id=5aad375c&scoped=true"
import script from "./DeliveryPoints.vue?vue&type=script&lang=js"
export * from "./DeliveryPoints.vue?vue&type=script&lang=js"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "@/assets/styles/multiselect.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "@/assets/styles/beneficiary.css?vue&type=style&index=2&id=5aad375c&prod&scoped=true&lang=css&external"
import style3 from "./DeliveryPoints.vue?vue&type=style&index=3&id=5aad375c&prod&scoped=true&lang=css"
import style4 from "./DeliveryPoints.vue?vue&type=style&index=4&id=5aad375c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aad375c",
  null
  
)

export default component.exports